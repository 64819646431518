import React from "react";
import Layout from "../components/layout/Layout";
import Navbar from "../components/layout/header/Navbar";

import Footer from "../components/layout/footer/Footer";
import HeroSix2 from "../themes/index-6/HeroSix2";
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'


import twi from '../img/THL-logo.png'
import hmu from '../img/LOGO-HMU-ME-GNWMIKO-CMYK-ENG-DEFAULT-2.png'
import bendabl from '../img/bendabl_logo_black.png'
import ruprect from '../img/heiden.svg'
import scuola from '../img/santanna.png'
import iuvo from '../img/IUVO logo_payoff.png'
import eut from '../img/Eurecat_logo.svg'
import ijs from '../img/JSI.svg'
import uot from '../img/Twente_logo-stacked.png'
import rrd from '../img/RRD-Logo-2022.png'
import he from '../img/hert.svg'
import bul from '../img/New-BIC-logo.png'
import icl from '../img/IMPERIAL_logo_RGB_Blue_2024.png'

export default function HomeSix() {
  return (
    <Layout>
      <Navbar darkBg/>
      <HeroSix2 title='Partners'/>
      <div className="wrapper">
        
 
        <section className="section section-about-us" id='partners'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <br/>
                <section class="section section-default mt-none mb-none" id='partnerz'>
<div class="container">

<div id='twi' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={twi} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://www.twi-hellas.com/ "><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}><b>TECH HIVE LABS</b> (THL) is a European centre of excellence in Robotics and AI, where bold ideas and applied research turn into industrial solutions with real impact. We blend the curiosity of a lab with the precision of a business leader to drive technology engineering and transform experimentation into real-world capability, empowering entrepreneurs and enterprises to innovate with confidence and deliver measurable results.
</p>
 
  <p style={{color:'#11556e',textAlign:'justify'}}>Our motto is "Serious Playground. Real Impact.", which is also our way of working. We offer diverse engagement models, from collaborative projects and knowledge exchange to engineering consulting and technology transfer, to suit the specific needs of research collaborators and industry partners and ensure that every endeavour drives real results.
  </p>

<hr/>
</div>

  <div id='hmu' style={{textAlign:'left'}}>
<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={hmu} style={{width:'80px'}}/>     <span> <a target="_blank" href="https://csrl.hmu.gr/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4>    
<hr/>
<p style={{color:'#11556e',textAlign:'justify'}}>The Hellenic Mediterranean University (HMU) is a public academic institution of Greece, with headquarters in Heraklion, Crete and branches in the cities of Chania, Rethimno, Agios Nikolaos, Ierapetra and Sitia. The Hellenic Mediterranean University was founded in early 2019 (Law 4610/2019, Official Gazette 70, issue A', 07/05/2019. Today it consists of six Faculties, with more than 15,000 undergraduate and postgraduate students and approximately 400 teaching and research staff. Its academic history starts in the late 1970.
</p>
 <p style={{color:'#11556e',textAlign:'justify'}}>
 The main goal of the School of Engineering is to provide high quality education and training in the field of Engineering. The School offers undergraduate programs corresponding to level 7 (integrated masters) of the European Qualifications Framework (EQF), postgraduate programs corresponding to level 7, as well as doctoral programs to obtain a PhD degree (level 8).</p>
 <p style={{color:'#11556e',textAlign:'justify'}}>The interdepartmental Control Systems & Robotics Laboratory (CSRL) is a partnership of the Department of Electrical & Computer Engineering and the Department of Mechanical Engineering of the School of Engineering of the Hellenic Mediterranean University (HMU), and promotes research, development, and education activities in the areas of control systems, robotics, soft robotics, automation and mechatronics. The project’s research and development efforts are hosted at the Control Systems and Robotics Laboratory (CSRL) of the Hellenic Mediterranean University. CSRL has extensive expertise and modern facilities for prototype development and testing of mechatronics and robotics systems.</p><hr/>
   <br/>
</div>

<div id='bendabl'style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={bendabl} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://bendabl.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>Bendabl is a high-tech SME specialising in pneumatically actuated soft robotic solutions. Based in Athens, Greece, the company’s team has over 10 years of experience across the entire lifecycle of customised robotics development, from mechanical design, and Finite-Element modelling to soft robot fabrication techniques and control algorithms.</p>
<p style={{color:'#11556e',textAlign:'justify'}}>Bendabl is comprised of a team of engineers with a passion about robotics of a very special breed: soft. The company’s core vision is that the future belongs to robots that can reach the pinnacle of adaptability; the very change of their physical properties.</p>  
<p style={{color:'#11556e',textAlign:'justify'}}>Having accumulated deep expertise in pneumatically-actuated mechanisms and soft structure manufacturing, Bendabl aspires to be the pioneers of this new generation of robots. To push the boundaries of their capability. To broaden the range of applications they can help with. And most importantly, to flatten the learning curve of both developing and deploying them.</p>  
<hr/>
</div>



<div id='ruprect' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={ruprect} style={{width:'100px'}}/> <span> <a target="_blank" href="https://www.uni-heidelberg.de/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>Founded in 1386, Heidelberg University is the oldest university in today’s Germany and one of Europe’s most research-intensive institutions. Its successes in the Excellence Competitions of the German federal and state governments, and in internationally recognised rankings, prove Heidelberg University’s leading role in the scientific community. Heidelberg University provides continuous support to the research with modern facilities and independent labs able to produce in house the technological components, data analysis, and host extensive human testing. This includes the Heidelberg Center for Motion Research (HCMR) and the Assistive Technology and Interactive Exosuits Lab (ARIES). ARIES lab (Assistive Robotics and Interactive ExosuitS laboratory) works on three main topics: (i) rehabilitation engineering, (ii) soft wearable robotics and (iii) intelligent actuators and controllers. The main scope of ARIES lab is to conceive, design and develop wearable technology, which is able to support human beings in multiple scenarios, ranging from rehabilitation and personalised assistance to empowering workers in working environments, enhancing safety and productivity.
</p>
<hr/>
</div>


<div id='scuola' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={scuola} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://www.santannapisa.it/en/institute/biorobotics"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>The Scuola Superiore Sant'Anna is a public University with a special status admitting excellent students at graduate, doctoral and post-doctoral level in the sectors of engineering, medicine, agriculture, economics, law and political science. The mission of SSSA is to perform excellent research through a number of highly qualified research institutes.
</p><p style={{color:'#11556e',textAlign:'justify'}}>
The BioRobotics Institute of SSSA conducts theoretical and experimental research in biorobotics, a discipline characterized by a high degree of interdisciplinarity. For this reason, it has a strong tendency toward integrating heterogeneous bodies of knowledge, of both scientific and humanistic nature, in order to study the theoretical and practical problems associated with the development of advanced robotic systems. Several robotic platforms have been developed in the framework of national and international projects, as for example humanoid robots, biomimetic robots, robotic and prosthetic hands, robotic systems for rehabilitation and for personal assistance of disable and elderly people.
</p>
<p style={{color:'#11556e',textAlign:'justify'}}>The DIRPOLIS Institute (Institute of Law, Politics and Development) is a research center devoted to interdisciplinary studies in the fields of law, economics and political sciences. Its core mission is to conduct innovative research by adopting a multidisciplinary approach to offer a comprehensive and multi-faceted representation of legal, social, economic and political phenomena. The Institute strives to provide scientifically sound and action-oriented bases to effective decision-making processes, at both private and public levels.</p>
<hr/>
</div>


<div id='iuvo' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={iuvo} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://www.iuvo.company/ "><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>IUVO is a spin-off company of The BioRobotics Institute of Scuola Superiore Sant’Anna (Pisa, Italy). Its mission is to develop innovative wearable robotics technologies and foster their market
exploitation in different market fields, such as medical, industrial and consumer. In August 2017, a joint venture between COMAU (an Italian company market leader in the field of industrial
automation and robotics; COMAU belongs to the Stellantis group) and Össur (an Icelandic
company market leader in the fields of prosthetics and orthotics) invested in IUVO, with the
ultimate goal to foster a wide adoption of wearable robotic technologies in daily-life scenarios.
Currently, IUVO is focusing on the development of passive occupational exoskeletons for worker
support in collaboration with COMAU (https://mate.comau.com/), and of an active pelvis orthosis
for the gait support in patients, in collaboration with Össur.
</p>
<hr/>
</div>


<div id='eut' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={eut} style={{width:'100px'}}/>   <span> <a target="_blank" href="www.eurecat.org"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>Eurecat is the main Research & Technology Organisation in Catalonia and the second largest private research organization in southern Europe. It brings together the experience of more than 750 professionals who generate an annual turnover of 62 million euros and provides services to more than 2,000 companies. Applied R&D, technological services, highly specialized training, technological consultancy or valorisation and exploitation of industrial property are some of the services that Eurecat offers for both large and small and medium-sized enterprises in all sectors. The technology centre participates in more than 200 large national and international consortium projects of high strategic R&I and has 181 patents and 10 spin-offs. The added value provided by Eurecat accelerates innovation, reduces spending on scientific and technological infrastructures, reduces risks and provides specialized knowledge tailored to each company. It has eleven centres in Catalonia (Spain) and one in Chile (Latam). 
</p><hr/>
</div>


<div id='abb' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={ijs} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://nbr.ijs.si/ "><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>Jožef Stefan Institute is Slovenia's leading research organization, engaging in basic and applied research in physics, chemistry, biochemistry, electronics, information science, nuclear technology, energy utilization, and environmental science. Founded in 1949, it has since expanded its focus to include various scientific and economic fields. The institute is deeply involved in educational initiatives, collaborating with universities and hosting postgraduate students. Its international collaborations and efforts in transferring research results to practical applications are notable. The institute also emphasizes the importance of applied research in ecology and medical technology. In the scope of the SWAG project the research group from the Laboratory of Neuromechanics and Biorobotics (https://nbr.ijs.si/) will be providing its expertise in integrating neuromechanics, robotics, and machine learning for studying human motor control and developing advanced robotic solutions. The NBR Lab group explores the robustness and adaptability of human movement in varied environments, vital for effective interaction with humans and robots. By analysing motion kinematics and dynamics, muscle activations, and brain signals, they create computational models of whole-body motor behaviour, uncovering the neural mechanisms for generating motor commands. Their research contributes to the design of a wide range of robotic systems, including industrial robots, humanoids, exoskeletons, and rehabilitation devices. They actively publish their findings in scientific journals and present at conferences.
</p>

<hr/>
</div>

<div id='aristotle' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={uot} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://www.utwente.nl/en/et/be/research/neuro-mech-eng-group/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>The University of Twente (UT) is a public research university located in Enschede, The Netherlands. It was founded in 1961 and hosts currently over 10,000 students and 2,700 staff members. The UT offers degrees in the fields of both social and technical sciences. The UT is committed to making economic and social contributions to society. The UT’s department participating in this proposal is the Chair of Neuromechanical Engineering. We have world-leading expertise in human motor control, ergonomics, biomechanics and design of biomechatronic orthotic and prosthetic systems for supporting and training the human motor system. These biomechatronic systems are developed and clinically evaluated in healthy individuals as well as in patients with stroke, spinal cord injury and amputation in close collaboration with national and international industries and leading rehabilitation departments. Together with industrial leaders and an extensive network of clinical institutions, we cover the entire trajectory from modelling a given subject population to the development of assistive robotics technologies. Our work is facilitated by the University's TechMed Centre, the Robotics Center, and the Digital Society Institute.</p>
<hr/>
</div>

<div id='rrd' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={rrd} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://www.rrd.nl/en/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>Roessingh Research and Development (RRD) is an impact lab for personalized Health Technology within the context of movement (sports and rehabilitation) and health management (chronic disorders and healthy ageing). RRD provides scientific research on innovative healthcare technology with an end-user focus. We use the needs of the end user as a starting point in the development of technology and ensure that the technology fits the healthcare context. So that technology is actually and optimally used and contributes to sustainable healthcare.
</p>
<p style={{color:'#11556e',textAlign:'justify'}}>
RRD has strong formalized links to one of the largest rehabilitation centres in the Netherlands (Roessingh Rehabilitation Center) and the University of Twente. RRD has extensive experience in many aspects of the development of innovative technology-supported health and care services; from initial scenario development and requirements elicitation with all stakeholders, technology development (ambulant sensing, IT services platform, Decision Support, personal context-aware coaching systems), user studies focused on usability, trust, acceptance and user satisfaction, large-scale clinical evaluation studies up to implementation of new services in daily clinical practice, and Social Return on Investment (SROI) analyses. These research and development activities are for a large part focused on health innovations for people with chronic diseases.</p>
<hr/>
</div>

<div id='he' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={he} style={{width:'100px'}}/>   
<span> <a target="_blank" href="https://robotics.herts.ac.uk"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>With its heritage in Britain’s pioneering aeronautical industry, UH has been an innovative force in education for 70 years. Today, it hosts a thriving community of more than 25,000 students who study 300 flexible degree options at a vibrant, inclusive, campus-based institution. UH plays a vital role in STEM skills development regionally, creating much-needed opportunities and inspiring the next generation.
</p>
<p style={{color:'#11556e',textAlign:'justify'}}>AI and Robotics expertise: School of Physics, Engineering and Computer Science is home to our Robotics Research Group consists of 12 academic members of staff including senior/junior research fellows, and a further 10 doctoral students.  In our unique ‘real world lab’, Robot House, funded through an ESPRC Strategic Equipment Award, researchers explore how to ensure robots act in a socially acceptable way to engender trust among the people that interact with them. Unique to the UK and one of only a handful globally, we regularly welcome external research groups and industry in the UK and internationally. </p>
<p style={{color:'#11556e',textAlign:'justify'}}>Our most high-profile success to date is Kaspar, the social robot. A world-first, Kaspar is specifically designed to interact with children with autism, supporting therapy and educational goals, learning about physical interaction and collaborative play. Long-term studies have demonstrated the benefits Kaspar can deliver; feedback from teachers, health professionals and parents has enabled further redesign and development. Kaspar has travelled internationally and has recently been licensed to a company in Canada, enabling wider use in educational settings.</p><hr/>
</div>

<div id='bul' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={bul} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://www.brunel.ac.uk/research/Centres/Brunel-Innovation-Centre/About-us "><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>The Brunel Innovation Centre (BIC) is a world class research and technology centre that sits between the knowledge base and industry offering high quality research in an innovative environment focused on non-destructive testing, condition and structural health monitoring, power ultrasonics and allied technologies covering a range of materials, sensors, electronics and software systems supporting partners in industry to transfer academic research into industrial application.</p>
<p style={{color:'#11556e',textAlign:'justify'}}>BIC has extensive expertise in electronic hardware development and the team is often involved from the concept-creation stage to system deployment.  The Cambridgeshire site has a functionally equipped lab that can facilitate device assembly, rapid prototyping, troubleshooting and repairs of electronics systems. The Electronics team take advantage of modern Electronic Design Automation tools such as Altium designer to select components, specify and design printed circuit boards for bespoke electronics systems to suit research requirements. BIC’s engineers can perform pre-compliance tests and analyses including; overvoltage tests, electromagnetic assessments and thermal tests.
</p>
<p style={{color:'#11556e',textAlign:'justify'}}>BIC pursues initiatives that span national and international platforms including Innovate UK, EPSRC and EC. The Centre has been building a strong portfolio of projects in line with its multinational interdisciplinary vision.</p>
<hr/>
</div>

<div id='icl' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#11556e'}}><img src={icl} style={{width:'100px'}}/>   <span> <a target="_blank" href="https://www.imperial.ac.uk/bioengineering/research/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#11556e',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'inline-end'}}/></a></span></h4> 
<hr/>   
<p style={{color:'#11556e',textAlign:'justify'}}>Imperial is a public research university. Its mission is to achieve enduring excellence in research and education in science, engineering, medicine and business for the benefit of society. Imperial consistently ranks in the top 10 (6th in 2024) in the QS world university rankings and ranked first in employment prospects for its graduates in 2019 Guardian’s University Guide.
</p>
<p style={{color:'#11556e',textAlign:'justify'}}>The Department of Bioengineering conducts research at the interface of engineering with medicine and biology, covering a range of topics from molecular and cellular bioengineering to neurotechnology, regenerative medicine, biomechanics, and biomedical diagnostics.
</p>
<hr/>
</div>
<br/><br/><br/>
</div>
</section>
              </Col>
            </Row>
            </Container>
            </section>
           
       
       
       
      </div>
      <Footer space />
    </Layout>
  );
}
